import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Claudia Trifenna Manolang';
export const GIRL_NAME_SHORT = 'Claudia';
export const GIRL_FATHER_NAME = `Jefta Manolang`;
export const GIRL_MOTHER_NAME = `Yavanda Veronica Cilvia Langelo`;
export const GIRL_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The daughter of</b><br /> Mr. ${GIRL_FATHER_NAME} <br /> and Mrs. ${GIRL_MOTHER_NAME}`
    : `<b>Putri dari</b> <br />Bapak ${GIRL_FATHER_NAME} <br /> dan Ibu ${GIRL_MOTHER_NAME}`
  ;

// -> boy section
export const BOY_NAME = 'Christian Candle Runtuwene';
export const BOY_NAME_SHORT = 'Candle';
export const BOY_FATHER_NAME = `Billy Michel Runtuwene`;
export const BOY_MOTHER_NAME = `Elstini Tirsa Grace Waworuntu`;
export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The son of</b><br /> Mr. ${BOY_FATHER_NAME} <br /> and Mrs. ${BOY_MOTHER_NAME}`
    : `<b>Putra dari</b><br /> Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`
  ;

// -> bride section
export const BRIDE_HASHTAG = ``;
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'candleruntuwene';
export const IG_GIRL = 'claudiatrifennaaa';
export const IG_FILTER_URL = `https://www.instagram.com/ar/893824642307071/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/masansgaming/Candle%20Claudia/SEO_etQqO9z5u.jpg?updatedAt=1701739140561`;
export const SEO_URL = 'https://invitato.net/TheWeddingOfCandleClaudia';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1703314800;
export const EPOCH_RESEPSI_START = 1703314800;
export const EPOCH_END_EVENT = 1703340000;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-12-23');
export const DATE_RESEPSI = new Date('2023-12-23');

export const WEDDING_AKAD_TIME = '14.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Home Of C3 Freedom';
export const WEDDING_AKAD_LOC_ROAD = `Jl. K.H. Wahid Hasyim No.154, Kota Jakarta Pusat`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
.locale(DEFAULT_LANGUAGE)
.format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_AKAD_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;

export const WEDDING_RESEPSI_TIME = '18.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = 'Home Of C3 Freedom';
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. K.H. Wahid Hasyim No.154, Kota Jakarta Pusat`;
export const WEDDING_RESEPSI_DRESSCODE = ``;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
.locale(DEFAULT_LANGUAGE)
.format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_RESEPSI_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;
export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD • MMMM • YYYY');
export const IS_SAME_LOCATION =
  WEDDING_AKAD_LOC_NAME === WEDDING_RESEPSI_LOC_NAME &&
  WEDDING_AKAD_LOC_ROAD === WEDDING_RESEPSI_LOC_ROAD;

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://TheWeddingOfCandleClaudia.com/';
export const BROADCAST_WEDDING_LOCATION = `Home Of C3 Freedom`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_AKAD = `https://maps.app.goo.gl/eQ7xPzEVD9L5K4Na7?g_st=ic`;
export const GOOGLE_MAPS_RESEPSI = `https://maps.app.goo.gl/eQ7xPzEVD9L5K4Na7?g_st=ic`;
export const GOOGLE_MAPS_ADDRESS = `Home Of C3 Freedom`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Hillsong – Through It All`;
export const SOUND_URL = 'https://youtu.be/4GthZbt1lPo?si=5FewY3I8sQkeg3cR';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtube.com/live/tDLhE5rqGdg';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/tDLhE5rqGdg';
export const PREWEDDING_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbxUaZSPZmZGNp_FsQdiC0BC8qlesNM2SwSSttajp7wk-5BX5TbCkaroaDYOTNAH3mvX/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const INVITATO_URL = 'https://invitato.id';
export const INVITATO_IG_URL = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';